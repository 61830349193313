<!--
# Example usage:
<Button title="Save poster" @click="savePoster" />
-->

<template>
  <button class="UI_button" :class="{ recording: theme === 'recording' }">{{ title }}</button>
</template>

<script>
export default {
  props: ["title", "theme"],
  name: "Button",
  setup() {},
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.UI_button {
  font-size: 12px;
  @include theme(background-color, UI_toolBar_iconColor);
  @include theme(color, UI_window_lightBackground);
  border: 0;
  padding: 12px 30px;
  cursor: pointer;
  transition: 0.25s;
  user-select: none;
  border-radius: 10px;
}

.UI_button.recording {
  opacity: 1;
  background: #ff0000;
  color: #fff;
  border: #fff;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
</style>